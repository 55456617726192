.App {
    padding-top: 2.5em;
    padding-bottom: 5em;
}

@media only screen and (min-width: 1200px) {
    .ResumeHeader h1 { font-size: 2.0em; }
    .ResumeSubheader p { font-size: 1.5em; }
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
    .ResumeHeader h1 { font-size: 1.55em; }
    .ResumeSubheader p { font-size: 1.0em; }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
    .ResumeHeader h1 { font-size: 2.0em; }
    .ResumeSubheader p { font-size: 1.5em; }
}

.ResumeSection {
    padding-top: 2em;
}

.Percentile-Bar {
    display: flex;
    height: 8px;
    outline-color: transparent;
    background-color: rgba(0, 0, 0, 0.15);
    overflow: hidden;
}

.Percentile-Bar-Item {
    outline-color: transparent;
    outline: 2px solid transparent;
    box-sizing: border-box;
}

.Percentile-Bar,
.Percentile-Bar .Percentile-Bar-Item:only-child {
    border-radius: 6px;
}

.SkillLevels {
    padding-top: 2em;
    padding-bottom: 2em;
}

.SkillLevels-Loading {
    height: 160px;
}

.SkillLevels-Error {
    height: 0;
    animation: error-height 500ms;
}

@keyframes error-height {
    from {
        height: 160px;
    }
    to {
        height: 0;
    }
}

.SkillLevels-Descriptions {
    display: flex;
    justify-content: center;
    padding: 1em 0.5em;
    flex-wrap: wrap;
}

.Skill-Tag {
    padding: 0.5em 1em;
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.Skill-ColorTag {
    border-radius: 0.375em;
    margin-right: 0.3em;
    width: 0.75em;
    height: 0.75em;
    display: inline-block;
}

.Skill-NameTag {}

.Skill-PercentageTag {
    color: rgba(0, 0, 0, 0.4);
    margin-left: 0.3em;
}

.ResumeSection-Introduction {
    font-size: 1.25rem;
    text-align: justify;
    line-height: 1.75rem;
    padding-top: 0;
}

.Publication-Title {
    display: block;
}

.Publication-Authors {
    color: rgba(0, 0, 0, 0.4);
}

.Publication-Links {
    display: block;
}

.Publication-Abstract {}

.Publication-Available-Soon {
    color: rgba(0, 0, 0, 0.4);
}

.PersonalLinks {
    text-align: center;
}

a.nohighlight:not(:hover) {
    color: rgba(0, 0, 0, 0.87);
}

a:not(:hover) {
    color: #1279c6;
}

a.icon-link:not(:hover) {
    color: rgba(0,0,0,.4);
}

@media only screen and (max-width: 767px) {
    .ResumeSectionItem {
        margin-bottom: 3.2rem !important;
    }
}

@media only screen {
    .github-url {
        display: none;
    }
}

@media only print {

    .ResumeSubheader p { font-size: 1.5em; }

    .ResumeSection {
        padding-top: 1.5em;
    }

    .App {
        display: grid !important;
        grid-template-areas:
            "header header"
            "biography languages"
            "working-experience education"
            "working-experience education"
            "working-experience publications";
        grid-template-columns: 1.4fr 1fr;
        grid-column-gap: 2.0rem;
        padding: 2.0rem;
    }

    .Avatar, .LanguageButtons {
        display: none !important;
        /*
        width: 10em !important;
        margin-left: auto;
        margin-right: auto; */
    }

    .ResumeHeader { grid-area: header; }
    .ResumeSection-Introduction { grid-area: biography; }
    .ResumeSection-Publications { grid-area: publications; }
    .ResumeSection-Education { grid-area: education; }
    .ResumeSection-Working-Experience { grid-area: working-experience; }
    .ResumeSection-Certificates { grid-area: certificates; }
    .ResumeSection-Contact {
        grid-area: contact;
        display: none;
    }

    .ResumeSection-SkillLevels {
        grid-area: languages;
    }

    /**  remove links in print */
    .PersonalLinks {
        display: none;
    }

    .Publication-Links {
        display: none;
    }

    a {
        color: rgba(0,0,0,.87) !important;
    }

    /** remove skill bar, keep only percentages */
    .ResumeSection-SkillLevels {
    }

    .ResumeSection-SkillLevels .github-url {
        width: 100%;
        display: block;
        text-align: center;
        font-size: 1.25rem;
        padding-top: 0.75rem;
        padding-bottom: 0;
    }

    .Percentile-Bar {
        margin: 0 1.5em;
    }

    .SkillLevels-Descriptions {
        display: flex;
        padding: 1em 0.5em 0em 0.5em;
        flex-wrap: wrap;
    }


    .Skill-NameTag {}

    .Skill-PercentageTag {
        color: rgba(0, 0, 0, 0.6);
    }
}
